import React, { useState } from 'react';
import './App.css';

function App() {
  const [showHackathonLinks, setShowHackathonLinks] = useState(false);

  return (
    <div className="App">
      <h1>Newhouse Synthetic Media Lab Puget Server</h1>
      <div id="main-links">
        <div className="box" title="Note: To reset the chat/save as a separate file, the user should type in '!archiveChat'. You may need to open in incognito mode for it to work.'">
          <a target="_blank" href="https://rocketchat.newhousesyntheticmedialab.com/">RocketChat chatbot (New)</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://batchimage-m.newhousesyntheticmedialab.com/">Batch Image Generator</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://openwebui-m.newhousesyntheticmedialab.com/">Open WebUI (ChatGPT like interface for testing LLMs)</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://image-m.newhousesyntheticmedialab.com/">Stable Diffusion</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://llm2img-m.newhousesyntheticmedialab.com/">Batch Text Generator (And LLM Generation to Image Generator)</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://autopair-m.newhousesyntheticmedialab.com/">AutoPair</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://cloud-m.newhousesyntheticmedialab.com/">Puget Cloud Storage</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://quality-m.newhousesyntheticmedialab.com/">Anti Forensics Tool</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://meme-m.newhousesyntheticmedialab.com/">Meme Generator</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://jupyter-m.newhousesyntheticmedialab.com/">JupyterHub</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://swarm-m.newhousesyntheticmedialab.com/">SwarmUI - Has Flux Image Generator</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://imgauthv2.newhousesyntheticmedialab.com/">AI Image Detector V2</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://textdetector-m.newhousesyntheticmedialab.com/">Text Detector Model</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://voice-m.newhousesyntheticmedialab.com/">Simple Text to Speech Tool</a>
        </div>
        <div className="box">
          <a target="_blank" href="https://roop.newhousesyntheticmedialab.com/">ROOP Unleashed (video deepfake generator)</a>
        </div>
      </div>

      {/* Toggle hackathon-links based on showHackathonLinks state */}
      <div id="hackathon-links">
        <button className="expand-button" onClick={() => setShowHackathonLinks(!showHackathonLinks)}>
          Hackathon Links <i className={showHackathonLinks ? 'arrow up' : 'arrow down'}></i>
        </button>
        <div className={`hackathon-links-container ${showHackathonLinks ? 'active' : ''}`}>
          <div className="box">
            <a target="_blank" href="https://team0a.newhousesyntheticmedialab.com/">Team 0A</a>
          </div>
          <div className="box">
            <a target="_blank" href="https://team0b.newhousesyntheticmedialab.com/">Team 0B</a>
          </div>
          <div className="box">
            <a target="_blank" href="https://team1a.newhousesyntheticmedialab.com/">Team 1A</a>
          </div>
          <div className="box">
            <a target="_blank" href="https://team1b.newhousesyntheticmedialab.com/">Team 1B</a>
          </div>
          <div className="box">
            <a target="_blank" href="https://team2a.newhousesyntheticmedialab.com/">Team 2A</a>
          </div>
          <div className="box">
            <a target="_blank" href="https://team2b.newhousesyntheticmedialab.com/">Team 2B</a>
          </div>
          <div className="box">
            <a target="_blank" href="https://team3a.newhousesyntheticmedialab.com/">Team 3A</a>
          </div>
          <div className="box">
            <a target="_blank" href="https://team3b.newhousesyntheticmedialab.com/">Team 3B</a>
          </div>
          <div className="box">
            <a target="_blank" href="https://team4a.newhousesyntheticmedialab.com/">Team 4A</a>
          </div>
          <div className="box">
            <a target="_blank" href="https://team4b.newhousesyntheticmedialab.com/">Team 4B</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;